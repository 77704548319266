import React from "react"

import styles from "../../styles/main.module.css"

export default function Reflection() {
    return (
      <React.Fragment>
        <h2>My First Reflection On This Site</h2>
        <hr className={styles.top_seperator} />
        <p>
          I started a journalling habit back in 2020 when I really, really wanted to improve my life in certain ways (maybe I'll write about it later). Since then I came back to MIT, my habit peetered off, and more recently, this Spring, I've started it back up again. I have tried to use journaling to analyze how I've been running my life and see how I could do better. I've also used it to vent. To tell the truth, it doesn't work for venting that well. I prefer to talk to people. I just don't always have that luxury, or don't have the courage to do so.
        </p>
        <p>
          I've decided to move my journaling to my website because this will help me incrementally strengthen my courage to share how I feel and how I view life. I think it will also help me be more rational and effective about how I pursue my future. I've made a lot of emotional/irrational decisions and I think they have harmed me. I think... In the worst case I can use this to write my biography, which is something I've wanted to do.
        </p>
        <p>
          I'm not sure what I want to say for today. I guess the main life lesson which I never seem to take enough to heart, but which is important, is that we need to talk to others about our problems. It is better to be ashamed and embarassed or whatever and fix whatever problems we have, psychologically or otherwise, quickly and forever, rather than let them recur. I've fallen into the latter camp of not doing that many times and I promise you it doesn't work. You only have one life and you have to do everything in your power to make it work out well for you and society (etc).
        </p>
        <p>
          Right now I am a little bit at a crossroads of deciding what to do with my life. I'll write more about it tomorrow, now that I have the infrastructure. I'm trying to make this more or less daily. The main problem, though, is that I feel totally dissatisfied with my life. I feel dissatisfied with my platonic relationships, with my lack romantic relationship(s), with my academic performance, with my job prospects, with my business connections/network, and with even my ability to interact with others socially, to meet people, to interface with society generally. I'm sometimes anxious on the daily about these things and feel like my life is passing by my eyes and I'm doing nothing. I think a big part of it is not leaving my comfort zone even though my comfort zone really, really hurts. A friend of mine recently shared <a href="https://docs.google.com/document/d/13YjVAZtpdDBr4R-oF99yZ8Ch46CVMFDHxgkf7rZhyOM/edit">this</a> with me and I will probably look over it and form a plan soon. Right now I'm in the process of establishing psychological stability by pressing the buttons that I know will work, at least for now, without substantial effort (i.e. establishing a system to get administrative tasks, so they don't hover over me, establishing a workout system and goals so that I feel good physically, establishing a daily cognitive challenge to keep me competent technically and give me something I "completed" that day, etc...).
        </p>
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}